import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import {
  cardIndexSelector,
  setCardIndex,
  updateCard
} from 'state/entities/cards'
import { formFieldSelector } from 'state/forms'
import { selectVehicle, vehicleSelector } from 'state/lead'
import { rpPreviewModeSelector, updatedWipCard } from 'state/ui'

import { CATCH_ALL_FORM_ID } from 'common/constants'
import { CardInterface, RootState } from 'types'
import { CarouselCardDisplay, Props } from './CarouselCard.display'
import { isInventoryViewSelector } from 'state/entities/views/views.selectors'

type StateProps = Pick<
  Props,
  'index' | 'searchQuery' | 'selectedVin' | 'previewMode' | 'isInventoryView'
>

const mapStateToProps = (
  state: RootState,
  ownProps: { _id: string }
): StateProps => ({
  index: cardIndexSelector(state, ownProps._id),
  searchQuery: formFieldSelector(
    state,
    CATCH_ALL_FORM_ID,
    `vehicleSearch_${ownProps._id}`
  ).value,
  selectedVin: vehicleSelector(state).vehicleVin,
  previewMode: rpPreviewModeSelector(state),
  isInventoryView: isInventoryViewSelector(state)
})

type DispatchProps = Pick<
  Props,
  'onSubmit' | 'updateThisCard' | 'setIndex' | 'updatePreviewUI'
>

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: { _id: string; onSubmit: Props['onSubmit'] }
): DispatchProps => ({
  onSubmit: vehicle => {
    if (ownProps.onSubmit) {
      ownProps.onSubmit(vehicle)
    } else {
      dispatch(selectVehicle(vehicle))
    }
  },
  setIndex: index => {
    dispatch(setCardIndex({ _id: ownProps._id, index }))
  },
  updatePreviewUI: () => {
    dispatch(updatedWipCard({}))
  },
  updateThisCard: (data: Partial<CardInterface>) => {
    dispatch(updateCard({ _id: ownProps._id, data }))
  }
})

export const CarouselCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CarouselCardDisplay)
