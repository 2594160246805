import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { unreadCountSelector } from 'state/entities/conversations'
import {
  isToolbarEnabledSelector,
  navButtonsSelector,
  navigateView,
  progressSelector,
  rpPreviewModeSelector
} from 'state/ui'
import { RootState } from 'types'
import { NavButtonDisplay, Props } from './NavButton.display'
import { setView } from 'state/entities/views'
import { setCard } from 'state/entities/cards'
import { setInventoryView } from 'state/entities/views/views.actions'

type StateProps = Pick<
  Props,
  'progress' | 'unreadCount' | 'navButtonsLength' | 'toolbarMode' | 'previewMode'
>
const mapStateToProps = (
  state: RootState,
  ownProps: { viewId: string }
): StateProps => ({
  progress: progressSelector(state, ownProps.viewId),
  unreadCount: unreadCountSelector(state),
  navButtonsLength: navButtonsSelector(state).length,
  toolbarMode: isToolbarEnabledSelector(state),
  previewMode: rpPreviewModeSelector(state),
})

type DispatchProps = Pick<Props, 'navigate' | 'setView' | 'setCard' | 'setInventoryView'>
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  navigate: viewId => dispatch(navigateView({ viewId })),
  setView: view => dispatch(setView({ view })),
  setCard: card => dispatch(setCard({ card })),
  setInventoryView: (value) => dispatch(setInventoryView({ value }))
})

export const NavButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NavButtonDisplay)
