import { faBars as defaultIcon } from '@fortawesome/pro-light-svg-icons/faBars'
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import * as fetch from 'isomorphic-fetch'
import { darken } from 'polished'
import * as React from 'react'
import Circle from 'react-circle'
import { withTheme } from 'styled-components'
import {
  CardInterface,
  CardSequenceViewInterface,
  CardStackViewInterface,
  ThemeInterface
} from 'types'

import { Button, Flex, Spinner } from 'components/_utility'
import settings from 'settings'
import styled, { css } from 'styledComponents'
import messagesIconImage from './logo__outlined.png'
import rpApiService from 'services/rp.api'

export interface Props {
  className?: string
  theme: ThemeInterface
  icon?: string
  label: string
  progress: number
  unreadCount: number
  navButtonsLength: number
  reducingForHelpButtons: number
  selected?: boolean
  viewId: string
  toolbarMode: boolean
  previewMode: string
  navigate: (currentView: string) => void
  setView: (view: CardSequenceViewInterface | CardStackViewInterface) => void
  setCard: (card: CardInterface) => void
  setInventoryView: (value: boolean) => void
}

export const Component = ({
  className,
  theme,
  icon,
  label,
  progress,
  viewId,
  navigate,
  unreadCount,
  previewMode,
  setView,
  setCard,
  setInventoryView
}: Props) => {
  const [iconComponent, setIconComponent] = React.useState<IconDefinition>()
  React.useEffect(() => {
    if (icon) {
      fetch(`${settings.rootUrl}/icon/${icon}`)
        .then(iconFetch => iconFetch.json())
        .then(iconData => setIconComponent(iconData))
        .catch(() => setIconComponent(defaultIcon))
    }
  }, [icon])

  const checkVinCodeExist = () => {
    const url = window.location.href
    const vinRegex = /\b[a-hj-npr-z0-9]{17}\b/i
    const vinMatch = url.match(vinRegex)
    if (vinMatch) {
      return vinMatch[0]
    } else {
      return null
    }
  }

  const onNavButtonClick = async () => {
    if (label.toLowerCase() === 'inventory' && previewMode === 'none') {
      if (checkVinCodeExist()) {
        const {
          inventorySearchView,
          inventorySearchCard
        } = await rpApiService.getInventorySearchView(settings.companyId)
        if (inventorySearchView && inventorySearchCard) {
          await setView({ ...inventorySearchView, _id: viewId })
          await setCard(inventorySearchCard)
          setInventoryView(true)
        } else setInventoryView(false)
      } else setInventoryView(false)
    } else setInventoryView(false)
    navigate(viewId)
  }

  return (
    <Button navFooter className={className} onClick={() => onNavButtonClick()}>
      {icon && !iconComponent && <SpinnerIcon size="2x" />}
      {icon && iconComponent && (
        <NavIcon className="nav-label" icon={iconComponent} size="2x" />
      )}
      {!icon && !iconComponent && (
        <MessagesIcon column alignItems="center">
          {unreadCount ? (
            <span>{unreadCount > 9 ? '9+' : unreadCount}</span>
          ) : null}
          <div />
        </MessagesIcon>
      )}
      <div className="nav-label">{label}</div>
      <ProgressContainer>
        {progress > 0 && (
          <Circle
            progress={progress}
            size="36"
            lineWidth="21"
            showPercentage={false}
            progressColor={theme.green}
            bgColor={theme.gray.lightest}
          />
        )}
      </ProgressContainer>
    </Button>
  )
}

const SpinnerIcon = styled(Spinner)``

const NavIcon = styled(Icon)`
  height: 17px;
`

// width related with calculateToolbarWidth.ts
const StyledComponent = styled(Component)`
  align-items: center;
  height: 100%;
  width: ${p => {
    if (p.toolbarMode && p.reducingForHelpButtons === 2) {
      return '56px'
    }
    if (p.toolbarMode && p.reducingForHelpButtons === 1) {
      return '64px'
    }
    return '66px'
  }}
  padding: 0;
  border-radius: 0;

  color: ${p =>
    p.theme.isCadillacTheme
      ? p.theme.cadilacBackGroundColor
      : p.toolbarMode
      ? p.theme.navColors.lightest
      : p.theme.navColors.original};
  background: none;

  font-weight: 300;
  font-size: 11px;

  transition: background-color 0.25s;

  & > * {
    color: ${p =>
      p.theme.isCadillacTheme
        ? p.theme.cadilacBackGroundColor
        : p.toolbarMode
        ? p.theme.navColors.lightest
        : p.theme.navColors.original};
  }
  ${p =>
    p.selected &&
    css`
      ${p.toolbarMode
        ? `background-color: ${darken(0.03, p.theme.navColors.lightest)}`
        : `background-color: ${darken(0.03, p.theme.navColors.lighter)}`}
      & > * {
        color: ${p.theme.isCadillacTheme
          ? p.theme.cadilacBackGroundColor
          : p.theme.navColors.original};
      }
    `}

  ${p =>
    !p.navButtonsLength &&
    `
    flex: 1;
  `}
  &:hover {
    background-color: ${p =>
      p.toolbarMode
        ? darken(0.03, p.theme.navColors.lightest)
        : darken(0.03, p.theme.navColors.lighter)};
    color: ${p => p.theme.navColors.original};
    & > * {
      color: ${p => p.theme.navColors.original};
    }
  }
  &:focus {
    background-color: ${p =>
      p.toolbarMode
        ? darken(0.1, p.theme.navColors.lightest)
        : darken(0.1, p.theme.navColors.lighter)};
    color: ${p => p.theme.navColors.original};
    & > * {
      color: ${p =>
        p.theme.isCadillacTheme
          ? p.theme.cadilacBackGroundColor
          : p.theme.navColors.original};
    }
  }

  ${SpinnerIcon} {
    margin: ${p => (p.toolbarMode ? '0 0 4px' : '4px 0 6px')};
  }

  ${NavIcon} {
    margin: ${p => (p.toolbarMode ? '0 0 4px' : '6px 0 9px')};
  }
`

export const NavButtonDisplay = withTheme(StyledComponent)

const MessagesIcon = styled(Flex)`
  position: relative;
  text-align: center;
  width: 100%;
  height: 23px;
  margin: 4px 0 5px;

  span:first-child {
    position: absolute;
    z-index: 2;
    top: 5px;
    width: 16px;
    height: 7px;

    font-size: 10px;
    line-height: 0.9;
    background-color: white;

    transition: background-color 0.25s;
  }

  div:last-child {
    width: 100%;
    height: 100%;

    mask: url(${messagesIconImage});
    mask-position: 50% 50%;
    mask-repeat: no-repeat;
    mask-size: contain;
    background-color: ${p =>
      p.theme.isCadillacTheme
        ? p.theme.cadilacBackGroundColor
        : p.theme.navColors.original};
  }
`

const ProgressContainer = styled.div`
  position: absolute;
  top: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: inherit;
  height: 100%;
`
