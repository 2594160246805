import { createSelector } from 'reselect'

import { RootState } from 'state/reducer'

export const viewByIdSelector = createSelector(
  (state: RootState, _id: string) => state.entities.views.byId[_id],
  view => view
)

export const isInventoryViewSelector = createSelector(
  (state: RootState) => state.entities.views.isInventoryView,
  isInventoryView => isInventoryView
)
