import * as React from 'react'
import styled from 'styledComponents'

import { faChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'

import {
  Button,
  Card,
  ConnectedInput,
  Container,
  Flex,
  Spinner,
  Text
} from 'components/_utility'
import rpApiService from 'services/rp.api'
import { helpers } from 'style/mixins/'
import {
  ActionButtonActionInterface,
  CardHeaderProps,
  CardInterface,
  FlexProps,
  HelpersProps,
  InventoryElement,
  InventorySearchProps,
  ProductInterface,
  ThemeInterface
} from 'types'
import { CarouselProduct } from '.'

interface CardHeader {
  title: string
  alwaysShow: boolean
}

export interface Props extends HelpersProps, InventorySearchProps {
  className?: string
  theme?: ThemeInterface
  _id: string
  collapsed?: boolean
  header: CardHeaderProps | CardHeader
  products: ProductInterface[]
  index: number
  actionButtons?: ActionButtonActionInterface[]
  setIndex: (index: number) => void
  noResultsMessage: string
  updateThisCard: (data: Partial<CardInterface>) => void
  searchQuery?: string
  selectedVin?: string
  onSubmit?: (vehicle: InventoryElement) => void
  submitted?: boolean
  previewMode: string
  updatePreviewUI: () => void
  isCreditLogix: boolean
  isInventoryView: boolean
}

class Component extends React.Component<Props, { loading: boolean }> {
  constructor(props: Props) {
    super(props)

    this.state = {
      loading: false
    }
  }

  public getVinCode() {
    const url = window.location.href
    const vinRegex = /\b[a-hj-npr-z0-9]{17}\b/i
    const vinMatch = url.match(vinRegex)
    if (vinMatch) {
      return vinMatch[0]
    } else {
      return null
    }
  }

  public componentDidMount() {
    const { products, isCreditLogix, isInventoryView } = this.props
    if (isInventoryView) {
      const vinCode = this.getVinCode()
      console.log(vinCode)
      if (vinCode) this.searchInventory(vinCode)
    } else if ((!products || !products.length) && !isCreditLogix) {
      this.searchInventory()
    }
  }

  public handleSearchInventory = (e: React.SyntheticEvent) => {
    e.preventDefault()
    this.searchInventory()
  }

  public handleSelectVehicle = () => {
    const { submitted, onSubmit, products, index } = this.props
    if (!submitted && onSubmit) {
      onSubmit(products[index].original)
    }
  }

  public render() {
    const {
      className,
      header,
      collapsed,
      actionButtons,
      index,
      products = [],
      noResultsMessage,
      inventoryQuery,
      submitted,
      setIndex,
      selectedVin,
      onSubmit,
      isCreditLogix
    } = this.props

    const selected =
      (products[index] && products[index].original.vinCode) === selectedVin ||
      submitted
    let cardContent = null
    if (this.state.loading) {
      cardContent = (
        <Flex justifyContent="center" alignItems="center" pb={18} pt={18}>
          <Spinner size="2x" />
        </Flex>
      )
    } else {
      cardContent =
        products && products.length ? (
          <Container relative>
            <Products row index={index}>
              {products.map((product, i) => (
                <CarouselProduct key={i} product={product} />
              ))}
            </Products>
            <Flex row pl={24} pr={24} pb={18}>
              <ControlButton
                primary
                disabled={index === 0}
                onClick={() => setIndex(index - 1)}
                mr={5}
                customAttributes={{ 'data-test': 'prev-item' }}
              >
                <Icon icon={faChevronLeft} size="1x" />
              </ControlButton>
              {!!onSubmit && (
                <Button
                  _width={100}
                  primary
                  disabled={selected}
                  onClick={this.handleSelectVehicle}
                  customAttributes={{
                    title: selected ? 'Vehicle Selected' : 'Select Vehicle'
                  }}
                >
                  {selected ? 'Vehicle Selected' : 'Select Vehicle'}
                </Button>
              )}
              <ControlButton
                primary
                disabled={index === products.length - 1}
                onClick={() => setIndex(index + 1)}
                ml={5}
                customAttributes={{ 'data-test': 'next-item' }}
              >
                <Icon icon={faChevronRight} size="1x" />
              </ControlButton>
            </Flex>
          </Container>
        ) : (
          <Flex justifyContent="center" alignItems="center" column pb={18}>
            <Text data-test="inventory-noResultsMessage">
              {noResultsMessage}
            </Text>
          </Flex>
        )
    }

    return (
      <Card
        className={className}
        topbar={!!header}
        padding="none"
        header={
          header && (header as CardHeader).alwaysShow ? header : undefined
        }
        collapsed={collapsed}
        actionButtons={actionButtons}
        actionButtonsDisplay={{ padding: 'full' }}
        customAttributes={{ 'data-test': 'carousel-card' }}
      >
        <Flex column>
          {!!inventoryQuery && !isCreditLogix && (
            <Flex column padding="standard">
              <form onSubmit={this.handleSearchInventory}>
                <ConnectedInput
                  name={`vehicleSearch_${this.props._id}`}
                  search
                  onSearchClick={this.handleSearchInventory}
                  disabled={this.state.loading}
                  placeholder="Search inventory..."
                />
              </form>
            </Flex>
          )}
          {cardContent}
        </Flex>
      </Card>
    )
  }

  private searchInventory = (searchedVinCode: string | null = null) => {
    this.setState({ loading: true })
    rpApiService
      .searchInventory({
        searchQuery: searchedVinCode
          ? searchedVinCode
          : this.props.searchQuery || '',
        inventorySearchProps: {
          inventoryQuery: this.props.inventoryQuery,
          noResultsMessage: this.props.noResultsMessage,
          sort: this.props.sort,
          filters: this.props.filters,
          showPrice: this.props.showPrice,
          priceLabel: this.props.priceLabel,
          dataFields: this.props.dataFields
        }
      })
      .then((products: any) => {
        this.setState({ loading: false })
        this.props.updateThisCard({
          props: {
            index: 0,
            products
          }
        })
        if (this.props.previewMode !== 'none') {
          this.props.updatePreviewUI()
        }
      })
      .catch(() => {
        this.setState({ loading: false })
      })
  }
}

interface ProductsProps extends FlexProps {
  index: number
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Products = styled(({ index, ...rest }) => <Flex {...rest} />)<
  ProductsProps
>`
  position: relative;
  left: ${p => p.index && p.index * -100}%;

  transition: left 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
`

const ControlButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`

export const CarouselCardDisplay = styled(Component)`
  ${helpers}
`
