import actionCreatorFactory from 'typescript-fsa'

import { CardSequenceViewInterface, CardStackViewInterface } from 'types'

const actionCreator = actionCreatorFactory('views')

export const setView = actionCreator<{
  view: CardSequenceViewInterface | CardStackViewInterface
}>('SET_VIEW')

export const setInventoryView = actionCreator<{
  value: boolean
}>('SET_INVENTORY_VIEW')
