import api from 'utility/api/api.client'

import settings from 'settings'
import {
  AppointmentAvailabilityInterface,
  ChatbotInterface,
  ConversationSession,
  FaqInterface,
  InventoryElement,
  InventorySearchProps,
  Lead,
  MessageElement,
  PathAttributes,
  ProductInterface,
  Service,
  AdobeTagInterface
} from 'types'
import { FormDataInterface } from 'types/FormDataInterface'
import { mapInventoryElement } from 'utility/mapInventoryElement'

class RPApiService {
  private apiClient: any

  public init(sessionToken: string): void {
    this.apiClient = api({
      baseApiUrl: `${settings.rpApiUrl}/api/v1`,
      headers: { 'x-rp-session-token': sessionToken }
    })
  }

  public getLead = (leadId: string): Promise<Lead | null> => {
    return this.apiClient.get(`messenger/web/leads/${leadId}`)
  }

  public createLead = (data: {
    deviceType: string
    customData?: PathAttributes
  }): Promise<Lead> => {
    return this.apiClient.post('messenger/web/leads', null, data)
  }

  public patchLead = (
    leadId: string,
    data: {
      [x: string]: any
    }
  ): Promise<Lead> => {
    return this.apiClient.patch(`messenger/web/leads/${leadId}`, null, data)
  }

  public updateLeadVehicle = (
    leadId: string,
    vehicle: InventoryElement
  ): Promise<Lead> => {
    return this.apiClient.patch(`messenger/web/leads/${leadId}/vehicle`, null, {
      vehicle
    })
  }

  public getChatbot = (chatbotId: string): Promise<ChatbotInterface> => {
    return this.apiClient.get(`messenger/web/chatbots/${chatbotId}`)
  }

  public getChatbotFaqs = (actionId: string): Promise<FaqInterface[]> => {
    return this.apiClient.post('messenger/web/chatbots/faqs-search', null, {
      actionId
    })
  }

  public getConversation = (
    conversationId: string
  ): Promise<ConversationSession> => {
    return this.apiClient.get(`messenger/web/conversations/${conversationId}`)
  }

  public pingConversation = (data: {
    chatbotId: string
    pathId: string
    autoReplyMessage: string
  }): Promise<ConversationSession> => {
    return this.apiClient.post(`messenger/web/conversations`, null, data)
  }

  public replyAction = (
    conversationId: string,
    data: {
      text: string
      elements: MessageElement[]
    }
  ): void => {
    return this.apiClient.post(
      `messenger/web/conversations/${conversationId}/action-reply`,
      null,
      data
    )
  }

  public replyText = (
    conversationId: string,
    data: {
      text: string
    }
  ): void => {
    return this.apiClient.post(
      `messenger/web/conversations/${conversationId}/reply`,
      null,
      data
    )
  }

  public markRead = (conversationId: string): void => {
    return this.apiClient.post(
      `messenger/web/conversations/${conversationId}/mark-read`
    )
  }

  public replyChatbotForm = (
    conversationId: string,
    data: FormDataInterface
  ): void => {
    return this.apiClient.post(
      `messenger/web/conversations/${conversationId}/form`,
      null,
      data
    )
  }

  public submitConversationCard = (
    conversationId: string,
    cardId: string,
    data: any
  ): any => {
    return this.apiClient.post(
      `messenger/web/conversations/${conversationId}/cards/${cardId}/submit`,
      null,
      data
    )
  }

  public getServiceById = (serviceId: string): Promise<Service> => {
    return this.apiClient.get(`messenger/web/services/${serviceId}`)
  }

  public getServicesList = (): Promise<Service[]> => {
    return this.apiClient
      .get('messenger/web/services')
      .then(({ results }: { results: Service[] }) => results)
  }

  public getAvailability = (
    serviceId: string,
    lead: any = {}
  ): Promise<AppointmentAvailabilityInterface> => {
    return this.apiClient.get('messenger/web/availability', {
      serviceId,
      email: lead.email,
      phone: lead.phone
    })
  }

  public createAppointment = (data: any): Promise<any> => {
    return this.apiClient.post('messenger/web/appointments', null, data)
  }

  public submitViewForm = (data: FormDataInterface): Promise<any> => {
    return this.apiClient.post('messenger/web/content/form', null, data)
  }

  public searchInventory = (query: {
    searchQuery: string
    inventorySearchProps: InventorySearchProps
  }): Promise<ProductInterface[]> => {
    return this.apiClient
      .post('messenger/web/content/search-inventory', null, query)
      .then((inventory: InventoryElement[]) =>
        inventory.map(mapInventoryElement)
      )
  }

  public declineVideoCall = (messageId: string): Promise<any> => {
    return this.apiClient.post('video-chat/lead/decline', null, { messageId })
  }

  public leaveVideoCall = (leadId: string): Promise<any> => {
    return this.apiClient.post('video-chat/lead/leave', null, { leadId })
  }

  public joinVideoCall = ({
    lead,
    messageId
  }: {
    lead: { _id: string; firstName: string; lastName: string }
    messageId: string
  }): Promise<any> => {
    return this.apiClient.post('video-chat/lead/join', null, {
      lead,
      messageId
    })
  }

  public auditWidgetUrl = (data: {
    widgetUrl: string
    sourceUrl: string
  }): Promise<any> => {
    return this.apiClient.post('audit-widget', null, data)
  }

  public getAdobeTag = async (companyId: string): Promise<AdobeTagInterface> => {
    let company = await this.apiClient.get(`companies/${companyId}/adobeSettings`)
    return company.adobeTag
      ? { ...company.adobeTag }
      : { enabled: false }
  }

  public getInventorySearchView = async (companyId: string) => {
    const data = await this.apiClient.get(`companies/${companyId}/getInventorySearchView`)
    return data
  }
}

export default new RPApiService()
